import React from 'react';
import styled from '@emotion/styled';

const IconElement = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alightItems: 'center',
    // padding: '5px 0'
});

const IconMenu = ({ width, height }) => (
    <IconElement>
        <svg width="33" height="13" xmlns="http://www.w3.org/2000/svg">
            <g
                fillRule="nonzero"
                stroke="#13120E"
                fill="none"
                strokeLinecap="round"
            >
                <path d="M1 1h31M1 12h31" />
            </g>
        </svg>
    </IconElement>
);

export default IconMenu;
