import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTransition, animated } from 'react-spring';
import ProjectLink from 'components/molecules/projectLink';
import { MAX_WIDTH, MEDIA_QUERIES } from 'utils/constants';
import { gutter } from 'utils/mixins';

const Grid = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: MAX_WIDTH,
    pointerEvents: 'none',
});

const GridItem = animated(
    styled.div(
        {
            width: '100%',
            [MEDIA_QUERIES.SMALL]: {
                width: '50%',
            },
            [MEDIA_QUERIES.MEDIUM]: {
                width: '33.33%',
            },
        },
        gutter()
    )
);

const ProjectGrid = ({ projects, context }) => {
    const [hover, setHover] = useState(false);
    const transitions = useTransition(projects, (project) => project.slug, {
        initial: {
            opacity: 0,
            transform: 'translate3d(0%, 25%, 0)',
            pointerEvents: 'none',
        },
        enter: [
            {
                opacity: 1,
                transform: 'translate3d(0, 0, 0)',
            },
            {
                pointerEvents: 'all',
            },
        ],
        trail: 100,
        config: { mass: 1, tension: 275, friction: 85 },
    });

    return (
        <Grid
            onMouseLeave={() => {
                setHover(false);
            }}
        >
            {transitions.map(({ item, props, key }) => (
                <GridItem
                    key={key}
                    style={props}
                    onMouseEnter={() => {
                        setHover(key);
                    }}
                >
                    <ProjectLink
                        title={item.title}
                        slug={`${context}/${item.slug}`}
                        hover={hover}
                        number={key}
                        base64Placeholder={item.previewImage.blurUpThumb}
                        imageTitle={item.previewImage.title}
                        imageURL={item.previewImage.url}
                    />
                </GridItem>
            ))}
        </Grid>
    );
};

export default ProjectGrid;
