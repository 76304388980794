import React from 'react';
import styled from '@emotion/styled';

const IconElement = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alightItems: 'center'
});

const IconCross = () => (
    <IconElement>
        <svg width="23" height="22" xmlns="http://www.w3.org/2000/svg">
            <g
                fillRule="nonzero"
                stroke="#13120E"
                fill="none"
                strokeLinecap="round"
            >
                <path d="M.54 21.96L22.46.04M22.46 21.96L.54.04" />
            </g>
        </svg>
    </IconElement>
);

export default IconCross;
