import React from 'react';
import styled from '@emotion/styled';
import ArchivedProjectGrid from 'data/containers/archivedProjectGrid';
import { MEDIA_QUERIES, globalPadding } from 'utils/constants';

const ProjectsContainer = styled.div(
    {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '70px',
        [MEDIA_QUERIES.LARGE]: {
            paddingTop: 'calc(25vh - 84px) !important',
        },
    },
    globalPadding()
);

const ArchivedProjects = () => {
    return (
        <ProjectsContainer>
            <ArchivedProjectGrid />
        </ProjectsContainer>
    );
};

export default ArchivedProjects;
