import gql from 'graphql-tag';

const GET_PROJECT = gql`
    {
        allProjects {
            title
            slug
            description
            media {
                title
                url
                blurUpThumb
                width
                height
            }
        }
    }
`;

export default GET_PROJECT;
