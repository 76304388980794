import React from 'react';
import { useQuery } from 'react-apollo';
import GET_ARCHIVED_PROJECTS from 'data/queries/get-archived-projects.graphql.js';
import ProjectGrid from 'components/organisms/projectGrid';

const ProjectGridContainer = () => {
    const { loading, data } = useQuery(GET_ARCHIVED_PROJECTS);
    const projects = data ? data.allArchives : [];

    return loading ? (
        <div />
    ) : (
        <ProjectGrid projects={projects} context="archive" />
    );
};

// const ProjectGridInner = () => (

//     return <ProjectGrid projects={data.allProjects} />;

// );

export default ProjectGridContainer;
