import { guttr } from 'guttr';

const gutter = guttr({
    base: {
        gutter: 16
    },
    breakpoints: {
        small: {
            gutter: 16,
            mediaQuery: `@media(min-width: 768px)`
        },
        medium: {
            gutter: 16,
            mediaQuery: `@media(min-width: 992px)`
        },
        large: {
            gutter: 24,
            mediaQuery: `@media(min-width: 1300px)`
        }
    }
});

export { gutter };
