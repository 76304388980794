import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useUpdate } from 'data/context/project';
import PrevProjectLink from 'components/molecules/prevProjectLink';
import NextProjectLink from 'components/molecules/nextProjectLink';
import ProjectInfo from 'components/molecules/projectInfo';
import ProjectImages from 'components/molecules/projectImages';

const ImageCanvas = styled.div({
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    userSelect: 'none',
    willChange: 'transform',
});

function ImageBrowser({
    projectTitle,
    projectSlug,
    projectDescription,
    projectMedia,
    nextTitle,
    nextSlug,
    prevTitle,
    prevSlug,
    context,
}) {
    const dispatch = useUpdate();

    const showNextProjectLink = () => {
        dispatch({
            type: 'OPEN_NEXT_PREVIEW',
        });
    };
    const showPrevProjectLink = () => {
        dispatch({
            type: 'OPEN_PREV_PREVIEW',
        });
    };

    const imageRequested = (imgNum) => {
        dispatch({
            type: 'IMAGE_REQUESTED',
            payload: { imgNum },
        });
    };

    useEffect(() => {
        dispatch({
            type: 'PROJECT_LOADED',
        });
    }, [dispatch, projectTitle]);

    return (
        <ImageCanvas>
            <ProjectImages
                projectMedia={projectMedia}
                projectSlug={projectSlug}
                nextProject={showNextProjectLink}
                prevProject={showPrevProjectLink}
                imageRequested={imageRequested}
            />
            <NextProjectLink
                nextTitle={nextTitle}
                nextSlug={nextSlug}
                context={context}
            />
            <PrevProjectLink
                prevTitle={prevTitle}
                prevSlug={prevSlug}
                context={context}
            />
            <ProjectInfo
                title={projectTitle}
                description={projectDescription}
            />
        </ImageCanvas>
    );
}

export default ImageBrowser;
