import React from 'react';
import styled from '@emotion/styled';
import Branding from 'components/molecules/branding';
import PageTitle from 'components/molecules/pageTitle';
import { MEDIA_QUERIES, GUTTERS, DEBUG_LAYOUT } from 'utils/constants';

const Header = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    paddingRight: `${20 - GUTTERS.default / 2}px`,
    paddingLeft: `${20 - GUTTERS.default / 2}px`,
    [MEDIA_QUERIES.MEDIUM]: {
        justifyContent: 'center'
    },
    [MEDIA_QUERIES.LARGE]: {
        paddingRight: `${60 - GUTTERS.large / 2}px`,
        paddingLeft: `${60 - GUTTERS.large / 2}px`
    }
});

const BrandingContainer = styled.div({
    position: 'absolute',
    background: DEBUG_LAYOUT ? 'orange' : 'none',
    padding: '4px 10px 10px',
    right: '0px',
    [MEDIA_QUERIES.MEDIUM]: {
        padding: '10px',
        position: 'relative',
        top: '20px'
    }
});
const TitleContainer = styled.div({
    position: 'absolute',
    background: DEBUG_LAYOUT ? 'orange' : 'none',
    padding: '10px',
    right: '0px',
    [MEDIA_QUERIES.MEDIUM]: {
        position: 'absolute',
        top: '20px',
        right: '40px'
    }
});

const HeaderComponent = ({ title }) => (
    <Header>
        <BrandingContainer>
            <Branding />
        </BrandingContainer>
        <TitleContainer>
            <PageTitle>{title}</PageTitle>
        </TitleContainer>
    </Header>
);

export default HeaderComponent;
