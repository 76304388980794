import React from 'react';
import styled from '@emotion/styled';
import { useSpring, animated } from 'react-spring';
import ReactMarkdown from 'react-markdown';
import { useTracked } from 'data/context/project';
import {
    MEDIA_QUERIES,
    OFF_WHITE,
    GUTTERS,
    LIGHT_GREY,
    globalPadding,
    OFF_BLACK,
} from 'utils/constants';
import { gutter } from 'utils/mixins';

const ProjectInfoContainer = styled.div({
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: 4,
    userSelect: 'none',
    pointerEvents: 'none',
});

const ProjectInfo = animated(
    styled.div(
        {
            position: 'absolute',
            zIndex: '2',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-end',
            textAlign: 'right',
            width: '80%',
            height: '100%',
            background: OFF_WHITE,
            willChange: 'transform',
            pointerEvents: 'all',
            [MEDIA_QUERIES.MEDIUM]: {
                width: '50%',
            },
        },
        globalPadding()
    )
);

const BlankHitArea = styled.div(
    {
        pointerEvents: 'all',
        position: 'absolute',
        zIndex: '1',
        top: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        cursor: `url("
            data:image/svg+xml,
            %3Csvg xmlns='http://www.w3.org/2000/svg' width='56' height='56'%3E%3Cpath fill='%23FFF' d='M54.293.293l1.414 1.414L29.414 28l26.293 26.293-1.414 1.414L28 29.414 1.707 55.707.293 54.293 26.586 28 .293 1.707 1.707.293 28 26.586 54.293.293z'/%3E%3C/svg%3E
        ") 28 28, auto`,
        // background: `rgba(122, 212, 200, 0.5)`,
    },
    ({ isOpen }) => ({
        display: isOpen ? 'block' : 'none',
    })
);

const ProjectInfoContent = styled.section({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    paddingLeft: `${20 - GUTTERS.default / 2}px`,
    paddingRight: `${20 - GUTTERS.default / 2}px`,
});

const ProjectTitle = styled.h2(
    {
        fontSize: '1.2rem',
        fontWeight: '300',
        willChange: 'transform',
        // lineHeight: '32px',
        [MEDIA_QUERIES.MEDIUM]: {
            // fontSize: '40px',
            // lineHeight: '48px',
        },
    },
    gutter({ top: 0, right: 1, bottom: 1, left: 0 })
);

const ScrollContainer = styled.div(
    {
        height: '50%',
        overflowX: 'scroll',
    },
    gutter({ top: 0, left: 0, right: 1 })
);
const ProjectDescription = styled(ReactMarkdown)(
    {
        // fontSize: '15px',
        // lineHeight: '24px',
        maxWidth: '60vw',
        [MEDIA_QUERIES.MEDIUM]: {
            maxWidth: '30vw',
            // fontSize: '20px',
            // lineHeight: '28px',
        },
        '> p': {
            margin: `0 0 ${GUTTERS.small}px`,
            wordBreak: 'break-word',
            fontSize: '0.9rem',
            lineHeight: '1.5',
            a: {
                textDecoration: 'underline',
            },
        },
        a: {
            fontStyle: 'normal',
            fontSize: '0.9rem',
            textDecoration: 'none',
            color: OFF_BLACK,
            ':hover': {
                color: LIGHT_GREY,
            },
        },
        blockquote: {
            // textAlign: 'left',
            marginBlockStart: `0em`,
            marginBlockEnd: `0em`,
            marginInlineStart: `0em`,
            marginInlineEnd: `0em`,
            paddingLeft: `0rem`,
            paddingRight: `0.75rem`,
            margin: `1.5rem 0rem 1.5rem 0rem`,
            fontStyle: 'italic',
            borderRight: `0.1rem solid ${LIGHT_GREY}`,
            p: {
                lineHeight: '1.3rem',
            },
            [MEDIA_QUERIES.MEDIUM]: {
                paddingLeft: `1rem`,
                paddingRight: `1rem`,
                margin: `1.5em 0.5em`,
            },
            ':nth-of-type(1)': {
                marginTop: `0rem`,
                p: {
                    marginTop: 0,
                },
            },
        },
    },
    gutter({ top: 0, right: 0, left: 1 })
);

const ProjectInfoComponent = ({ title, description }) => {
    const [{ infoOpen }, dispatch] = useTracked();
    const { x } = useSpring({
        from: { x: -100 },
        x: infoOpen ? 0 : -100,
    });

    const closePanels = () => {
        dispatch({
            type: 'CLOSE_PANELS',
        });
    };

    return (
        <ProjectInfoContainer>
            <ProjectInfo
                style={{
                    transform: x.interpolate((x) => `translate3d(${x}%, 0, 0)`),
                }}
            >
                <ProjectInfoContent>
                    <ProjectTitle>{title}</ProjectTitle>
                    {description && (
                        <ScrollContainer>
                            <ProjectDescription source={description} />
                        </ScrollContainer>
                    )}
                </ProjectInfoContent>
            </ProjectInfo>
            <BlankHitArea isOpen={infoOpen} onClick={closePanels} />
        </ProjectInfoContainer>
    );
};

export default ProjectInfoComponent;
