import React from 'react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import ImageProfile from 'components/atoms/image/profile';
import ExhibitionList from 'components/organisms/exhibitionList';
import CollectionList from 'components/organisms/collectionList';
import ReactMarkdown from 'react-markdown';
import {
    MAX_WIDTH,
    MEDIA_QUERIES,
    GUTTERS,
    OFF_BLACK,
    GREY,
    globalPadding,
} from 'utils/constants';
import { gutter } from 'utils/mixins';

// TODO: Fix magic numbers ASAP
const ProfileContainer = styled.div(
    {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '70px',
        [MEDIA_QUERIES.MEDIUM]: {
            paddingTop: '170px',
        },
    },
    globalPadding()
);

const Profile = styled.div(
    {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: MAX_WIDTH,
        [MEDIA_QUERIES.MEDIUM]: {
            flexDirection: 'row',
            alignItems: 'flex-start',
        },
    },
    gutter()
);

const ProfileImage = styled.div(
    {
        width: '100%',
        [MEDIA_QUERIES.MEDIUM]: {
            width: '50%',
            // paddingRight: '60px !important',
            // paddingBottom: '70px !important',
            // paddingLeft: '80px !important',
        },
    },
    gutter({ top: 0, right: 0, bottom: 1.5, left: 0 })
);
const ProfileText = styled.div(
    {
        width: '100%',
        [MEDIA_QUERIES.MEDIUM]: {
            width: '50%',
            paddingLeft: '60px !important',
            paddingRight: '80px !important',
        },
    }
    // gutter()
);

const ProfileIntroduction = styled(ReactMarkdown)(
    {
        fontSize: '0.9rem',
        lineHeight: '1.5',
        // fontSize: '20px',
        // lineHeight: '28px',
        // [MEDIA_QUERIES.MEDIUM]: {
        //     fontSize: '24px',
        //     lineHeight: '32px'
        // },
        '> p': {
            margin: `0 0 ${GUTTERS.small}px`,
        },
    },
    gutter({ top: 0, right: 0, left: 0 })
);

const Contact = styled.div({
    paddingTop: '30px',
});
const ContactTitle = styled.div(
    {
        color: GREY,
    },
    gutter({ left: 0 })
);
const ContactEmail = styled.div(
    {
        fontSize: '20px',
        lineHeight: '28px',
        [MEDIA_QUERIES.MEDIUM]: {
            fontSize: '24px',
            lineHeight: '32px',
        },
    },
    gutter({ left: 0 })
);
const ContactLink = styled.a({
    color: OFF_BLACK,
});
const Copyright = styled.div({
    color: GREY,
    paddingTop: '80px',
    paddingBottom: '100px',
    fontSize: '15px',
    lineHeight: '24px',
    [MEDIA_QUERIES.MEDIUM]: {
        fontSize: '16px',
    },
});

const FooterLinkContainer = styled.div({
    display: 'flex',
    justifyContent: 'center',
    background: 'white',
    paddingTop: '85px',
    paddingBottom: '85px',
});
const FooterLink = styled(NavLink)({
    fontSize: '20px',
    lineHeight: '28px',
    textDecoration: 'none',
    color: OFF_BLACK,
    [MEDIA_QUERIES.MEDIUM]: {
        fontSize: '24px',
        lineHeight: '32px',
    },
});

const ProfilePage = ({
    profile: {
        image,
        introduction,
        contactEmail,
        soloExhibitions,
        groupExhibitions,
        collections,
    },
}) => {
    return (
        <div>
            <ProfileContainer>
                <Profile>
                    <ProfileImage>
                        <ImageProfile title={image.title} url={image.url} />
                    </ProfileImage>
                    <ProfileText>
                        <ProfileIntroduction>
                            {introduction}
                        </ProfileIntroduction>
                        <ExhibitionList
                            title="Selected Solo Exhibitions"
                            items={soloExhibitions}
                        />
                        <ExhibitionList
                            title="Selected Group Exhibitions"
                            items={groupExhibitions}
                        />
                        {collections.length > 0 && (
                            <CollectionList
                                title="Collections"
                                items={collections}
                            />
                        )}
                        <Contact>
                            <ContactTitle>Contact</ContactTitle>
                            <ContactEmail>
                                <ContactLink href={`mailto:${contactEmail}`}>
                                    {contactEmail}
                                </ContactLink>
                            </ContactEmail>
                        </Contact>
                        <Contact>
                            <ContactTitle>Represented by</ContactTitle>
                            <ContactEmail>
                                Sonja Pang
                                <br />
                                <ContactLink href="https://www.galleryathome.co.uk/">
                                    https://www.galleryathome.co.uk/
                                </ContactLink>
                            </ContactEmail>
                        </Contact>
                        <Copyright>
                            All images © copyright Toril Brancher.
                        </Copyright>
                    </ProfileText>
                </Profile>
            </ProfileContainer>
            <FooterLinkContainer>
                <FooterLink to="/">Selected Works</FooterLink>
            </FooterLinkContainer>
        </div>
    );
};

export default ProfilePage;
