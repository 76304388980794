import { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import { getEnv } from 'env.js';

function useTrackedHistory() {
    const GA_ID = getEnv('GA_ID');
    const history = createBrowserHistory();
    ReactGA.initialize(GA_ID);

    ReactGA.set({ page: history.location.pathname });
    ReactGA.pageview(history.location.pathname);

    useEffect(() => {
        const unlisten = history.listen((location, action, ...props) => {
            ReactGA.set({ page: location.pathname });
            ReactGA.pageview(location.pathname);
        });
        return () => {
            unlisten();
        };
    }, [history]);

    return history;
}

export { Router, useTrackedHistory };
