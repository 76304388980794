/**
 *  @fileOverview Utility functions for providing image urls for HTML5 srcset using 'imgix', used to be 'contentful', documenation below for either provider
 *  - https://docs.imgix.com/apis/url
 *  - https://www.contentful.com/developers/docs/references/images-api/#/reference
 *
 *  @author Thorbjorn Brancher
 *
 */

const DEFAULT_OPTIONS = {
    fm: 'jpg',
    q: '69'
};

// TODO: Document this function
// Creates an option string, specific to Contentful image API
export function createOptionsString(options) {
    let optionsString = '';

    if (options) {
        Object.entries(options).forEach(([key, value], index) => {
            const separator = index === 0 ? '?' : '&';
            return (optionsString += `${separator}${key}=${value}`);
        });
    }

    return optionsString;
}

/**
 * @summary Create SrcSet string for HTML5 <img srcSet="" /> for specific image hosts with provided options
 * @function
 * @public
 *
 * @description
 * Generate a srcSet string with passed options specific to a CDN provider
 *
 * @param {String} url - URL of CDN
 * @param {Array} sizes - Array of objects that contain `w`, `h` (optional) and their own options object if required
 * @param {Object} options - Object with `key` `value` pair for options supported by the image provider that get appended to the src string
 *
 * @returns {String} HTML5 image `srcSet`
 *
 * @example
 * const srcDef = imageSrcSet(
 *      'https://picsum.photos',
 *      [
 *          {
 *              w: 640,
 *              h: 360,
 *              options: {
 *                  q: 69
 *              }
 *          },
 *          {
 *              w: 320,
 *              h: 180
 *          }
 *      ]
 * );
 *
 *
 */
export function imageSrcSet(url, sizes, options = { fm: 'jpg', q: 69 }) {
    let srcString = ``;

    sizes.forEach(size => {
        const combinedOptions = {
            ...DEFAULT_OPTIONS,
            ...(size.w && { w: size.w }),
            ...(size.h && { h: size.h }),
            ...options,
            ...(size.options && size.options)
        };
        const optionString = createOptionsString(combinedOptions);

        srcString += `${url}${optionString} ${size.w}w, `;
    });

    return srcString;
}

/**
 * @summary Create image url string for <img src="" />
 * @function
 * @public
 *
 * @description
 *
 * @param {String} url - URL of CDN
 * @param {Array} sizes - Array of objects that contain `w`, `h` (optional) and their own options object if required
 * @param {Object} options - Object with `key` `value` pair for options supported by the image provider that get appended to the src string
 *
 * @returns {String} Url for HTML Image `src` attribute
 *
 * @example
 * const srcDef = imageSrc(
 *      'https://picsum.photos',
 *      {
 *          w: 640,
 *          h: 360,
 *          options: {
 *              q: 69
 *          }
 *      }
 * );
 *
 */
export function imageSrc(url, size, options = {}) {
    const combinedOptions = {
        ...DEFAULT_OPTIONS,
        ...(size.w && { w: size.w }),
        ...(size.h && { h: size.h }),
        ...options,
        ...(size.options && size.options)
    };
    const optionString = createOptionsString(combinedOptions);
    const srcString = `${url}${optionString}`;

    return srcString;
}
