import gql from 'graphql-tag';

const GET_PROJECTS = gql`
    {
        allProjects {
            title
            slug
            previewImage {
                title
                url
                blurUpThumb
            }
        }
    }
`;

export default GET_PROJECTS;
