import React from 'react';
import { useQuery } from 'react-apollo';
import GET_ARCHIVED_PROJECT from 'data/queries/get-archived-project-with-slug.graphql.js';
import { Provider } from 'data/context/project';
import ProjectPage from 'components/templates/projectPage';

function getNextProjects(projects, start, count) {
    let nextProjects = [];
    let nextIndex = 0;

    for (let step = 1; step <= count; step++) {
        nextIndex = start + step;
        // If we have hit the end
        if (nextIndex === projects.length) {
            nextIndex = 0;
            // If we are past the end
        } else if (nextIndex > projects.length) {
            nextIndex = step;
        }

        if (projects[nextIndex]) {
            nextProjects.push(projects[nextIndex]);
        }
    }

    return nextProjects;
}

function getPreviousProjects(projects, start, count) {
    let previousProjects = [];
    let nextIndex = 0;

    for (let step = -1; step >= -count; step--) {
        nextIndex = start + step;

        // If we have hit the bottom
        if (nextIndex < 0) {
            nextIndex = projects.length + nextIndex;
        }

        if (projects[nextIndex]) {
            previousProjects.push(projects[nextIndex]);
        }
    }

    return previousProjects;
}

function getProject(slug, projects) {
    return projects.find((item) => {
        return item.slug === slug;
    });
}

function getRelatedProjects(slug, projects, amount) {
    let project = null;
    let projectIndex = 0;
    let relatedProjects = [];

    if (projects.length) {
        // Get project
        project = getProject(slug, projects);
        // Get index
        projectIndex = projects.indexOf(project);

        // Related projects
        relatedProjects = getNextProjects(
            projects,
            projectIndex,
            amount
        ).concat(getPreviousProjects(projects, projectIndex, amount));
    }

    return relatedProjects;
}

function ProjectPageContainer({
    match: {
        params: { slug },
    },
}) {
    const { loading, data } = useQuery(GET_ARCHIVED_PROJECT, {
        variables: { slug },
    });

    const projects = data ? data.allArchives : [];
    const relatedProjects = getRelatedProjects(slug, projects, 1);
    const currProject = getProject(slug, projects);
    const nextProject = relatedProjects[0];
    const prevProject = relatedProjects[1];

    return (
        <Provider>
            {!loading && (
                <ProjectPageInner
                    currProject={currProject}
                    nextProject={nextProject}
                    prevProject={prevProject}
                />
            )}
            {/* {error} */}
            {/* {loading} */}
        </Provider>
    );
}

function ProjectPageInner({ currProject, nextProject, prevProject }) {
    const projectTitle = currProject.title;
    const projectSlug = currProject.slug;
    const projectDescription = currProject.description;
    const projectMedia = currProject.media;
    const nextTitle = nextProject.title;
    const nextSlug = nextProject.slug;
    const prevTitle = prevProject.title;
    const prevSlug = prevProject.slug;
    const totalMedia = projectMedia.length;

    return (
        <ProjectPage
            projectTitle={projectTitle}
            projectSlug={projectSlug}
            projectDescription={projectDescription}
            projectMedia={projectMedia}
            totalMedia={totalMedia}
            nextTitle={nextTitle}
            nextSlug={nextSlug}
            prevTitle={prevTitle}
            prevSlug={prevSlug}
            context="archive"
        />
    );
}

export default ProjectPageContainer;
