import { createContext } from 'react';

const ApplicationContext = createContext({
    menu: false,
});

const ApplicationReducer = (state, action) => {
    switch (action.type) {
        case 'TOGGLE_MENU':
            return { ...state, menu: !state.menu };
        case 'CLOSE_MENU':
            return { ...state, menu: false };
        default:
            return state;
    }
};

export { ApplicationContext, ApplicationReducer };
