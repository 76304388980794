// TODO: Establish the 'right' location for constants.js and mixins.js

// Colours
export const OFF_BLACK = '#2D2B24';
export const GREY = '#7F7E7B';
export const LIGHT_GREY = '#D8D7D4';
export const OFF_WHITE = '#F7F6F2';

// Max width
export const MAX_WIDTH = '1320px';

// Fonts
export const FONT_SANS_SERIF =
    'Graphik, -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif';
// export const FONT_SERIF =

// Breakpoints
// TODO, casing for nested props
export const BREAKPOINTS = {
    small_only: '(max-width: 767px)',
    small: '(min-width: 768px)',
    medium: '(min-width: 992px)',
    large: '(min-width: 1300px)',
};

// Make a CSS --var
export const DEBUG_LAYOUT = false;

// MQ for CSSinJS
export const MEDIA_QUERIES = {
    SMALL_ONLY: `@media only screen and ${BREAKPOINTS.small}`,
    SMALL: `@media only screen and ${BREAKPOINTS.small}`,
    MEDIUM: `@media only screen and ${BREAKPOINTS.medium}`,
    LARGE: `@media only screen and ${BREAKPOINTS.large}`,
};

// Gutters
export const GUTTERS = {
    default: 16,
    small: 16,
    medium: 16,
    large: 24,
};

// Application & design specific dimensions

export const SIDEBAR_WIDTH = 33;
export const VERTICAL_SITE_PADDING = 80; // Total of both top and bottom gutters added together
export const HORIZONTAL_SITE_PADDING = 120; // Total of both left and right gutters added together
export const LOGO_TEXT_WIDTH = 116;

// Park this here for the moment
// Todo: name this better....
export const globalPadding = () => ({
    paddingRight: `${20 - GUTTERS.default / 2}px`,
    paddingLeft: `${20 - GUTTERS.default / 2}px`,
    [MEDIA_QUERIES.LARGE]: {
        paddingRight: `${60 - GUTTERS.large / 2}px`,
        paddingLeft: `${60 - GUTTERS.large / 2}px`,
    },
});
