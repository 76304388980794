import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { ApplicationContext } from 'data/context/application';
import IconMenu from 'components/atoms/icon/menu';
import IconCross from 'components/atoms/icon/cross';
import { DEBUG_LAYOUT } from 'utils/constants';

const MenuButton = styled.div({
    cursor: 'pointer',
    padding: '10px',
    background: DEBUG_LAYOUT ? 'lightblue' : 'none'
});

const MenuButtonComponent = () => {
    const {
        state: { menu },
        dispatch
    } = useContext(ApplicationContext);

    return (
        <MenuButton
            onClick={() =>
                dispatch({
                    type: 'TOGGLE_MENU'
                })
            }
        >
            {menu ? <IconCross /> : <IconMenu />}
        </MenuButton>
    );
};

export default MenuButtonComponent;
