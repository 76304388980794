import React from 'react';
import styled from '@emotion/styled';
import { useSpring, animated } from 'react-spring';
import ProjectImageCount from 'components/molecules/projectImageCount';
import InfoLink from 'components/molecules/infoLink';
import ImageBrowser from 'components/organisms/imageBrowser';
import Header from 'components/organisms/header/project';
import { SIDEBAR_WIDTH, DEBUG_LAYOUT, MEDIA_QUERIES } from 'utils/constants';
// import { gutter } from 'utils/mixins';

// TODO: Fix magic numbers ASAP
const PageContainer = styled.div({
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'stretch', // for teh children so they can be 100% tall
    height: `100%`,
    [MEDIA_QUERIES.MEDIUM]: {
        flexDirection: 'row',
        paddingRight: `${60}px`,
        paddingLeft: `${60}px`,
    },
});

const HeaderContainer = animated(
    styled.div({
        // background: 'lightblue',
        position: 'absolute',
        top: '0px',
        right: '0px',
        zIndex: '5',
        background: DEBUG_LAYOUT ? 'lightgrey' : 'none',
        [MEDIA_QUERIES.MEDIUM]: {
            position: 'relative',
            width: `${SIDEBAR_WIDTH}px`,
        },
    })
);

const ProjectContainer = styled.div({
    position: 'relative',
    flexGrow: '1',
    width: '100%',
    height: '100%',
    padding: '60px 0px',
    // height: `calc((var(--vh, 1vh) * 100) - ${100}px)`, // temp?
    // height: '100%',
    background: DEBUG_LAYOUT ? 'lightgreen' : 'none',
    [MEDIA_QUERIES.MEDIUM]: {
        padding: '40px 60px',
    },
});

// Count and Info here for mobile
const FooterContainer = styled.div({
    position: 'relative',
    display: 'flex',
    // justifycontent
    justifyContent: 'flex-end',
    // background: 'lightblue',
    [MEDIA_QUERIES.MEDIUM]: {
        width: `${SIDEBAR_WIDTH}px`,
    },
});

const InfoLinkContainer = styled.div({
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    padding: '10px',
    background: DEBUG_LAYOUT ? 'orange' : 'none',

    [MEDIA_QUERIES.MEDIUM]: {
        display: 'none',
    },
});
const CountContainer = styled.div({
    position: 'absolute',
    bottom: '0px',
    right: '0px',
    zIndex: '3',
    padding: '10px',
    background: DEBUG_LAYOUT ? 'orange' : 'none',
});

// function setVHCSSVar() {
//     const vh = window.innerHeight * 0.01;
//     document.documentElement.style.setProperty('--vh', `${vh}px`);
// }

const ProjectPage = function ProjectPage({
    projectTitle,
    projectSlug,
    projectDescription,
    projectMedia,
    totalMedia,
    nextTitle,
    nextSlug,
    prevTitle,
    prevSlug,
    context,
}) {
    const { x } = useSpring({
        to: { x: 0 },
        from: { x: -100 },
    });

    // useEffect(() => {
    //     setVHCSSVar();
    //     window.addEventListener('resize', setVHCSSVar);
    //     return () => {
    //         window.removeEventListener('resize', setVHCSSVar);
    //     };
    // });

    return (
        <PageContainer>
            <HeaderContainer
                style={{
                    transform: x.interpolate((x) => `translate3d(${x}%, 0, 0)`),
                }}
            >
                <Header />
            </HeaderContainer>
            <ProjectContainer>
                <ImageBrowser
                    projectTitle={projectTitle}
                    projectSlug={projectSlug}
                    projectDescription={projectDescription}
                    projectMedia={projectMedia}
                    nextTitle={nextTitle}
                    nextSlug={nextSlug}
                    prevTitle={prevTitle}
                    prevSlug={prevSlug}
                    context={context}
                />
            </ProjectContainer>
            <FooterContainer>
                <InfoLinkContainer>
                    <InfoLink />
                </InfoLinkContainer>
                <CountContainer>
                    <ProjectImageCount totalMedia={totalMedia} />
                </CountContainer>
            </FooterContainer>
        </PageContainer>
    );
};

export default ProjectPage;
