import React from 'react';
import styled from '@emotion/styled';
import { OFF_BLACK, DEBUG_LAYOUT } from 'utils/constants';

const BrandingElement = styled.h1({
    display: 'flex',
    textDecoration: 'none',
    color: OFF_BLACK,
    background: DEBUG_LAYOUT ? 'lightblue' : 'none',
    padding: '10px',
    margin: '0px',
    // fontSize: '16px',
    fontSize: '1.2rem',
    WebkitFontSmoothing: 'subpixel-antialiased',
    fontWeight: '300',
    cursor: 'default',
});

const Branding = () => <BrandingElement>Toril Brancher</BrandingElement>;

export default Branding;
