import React, { useEffect, useRef } from 'react';
import { Switch, Route } from 'react-router-dom';
import styled from '@emotion/styled';
import HeaderStandard from 'components/organisms/header/standard';
import MenuLink from 'components/molecules/menuLink';
import SelectedProjects from 'components/templates/selectedProjects';
import ArchivedProjects from 'components/templates/archivedProjects';
import ProfilePage from 'data/containers/profilePage';
import ProjectPage from 'data/containers/projectPage';
import ArchivedProjectPage from 'data/containers/archivedProjectPage';
import Menu from 'components/organisms/menu';
import { FONT_SANS_SERIF, MEDIA_QUERIES, DEBUG_LAYOUT } from 'utils/constants';

// Still working out where core CSS defaults should be
// Some have been added to index.css...
// TODO: Fix magic numbers ASAP
const App = styled.div({
    position: 'relative',
    fontFamily: FONT_SANS_SERIF,
    height: '100vh',
    transition: 'height 350ms ease-in-out 0s',
    // fontSize: '16px',
    // lineHeight: '24px',
});

const MenuLinkContainer = styled.div({
    position: 'absolute',
    zIndex: '3',
    top: '0px',
    left: `0px`,
    padding: '10px',
    background: DEBUG_LAYOUT ? 'orange' : 'none',
    [MEDIA_QUERIES.MEDIUM]: {
        top: '20px',
        left: `40px`,
        // left: `${60 - GUTTERS.large / 2}px`
    },
});

const Body = styled.div({
    position: 'relative',
    zIndex: '1',
    height: '100%',
    // paddingTop: `15px`,
    background: DEBUG_LAYOUT ? 'hotpink' : 'none',
    [MEDIA_QUERIES.LARGE]: {
        // paddingTop: `40px`
    },
});

const AppComponent = () => {
    const appRef = useRef(null);
    const iOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    function setHeight() {
        setTimeout(() => {
            const ih = window.innerHeight;

            if (appRef.current) {
                appRef.current.style.height = `${ih}px`;
            }
        }, 500);
    }

    useEffect(() => {
        if (iOS) {
            setHeight();
            // appRef.current.style.overflow = 'hidden';
            window.addEventListener('resize', setHeight);
            return () => {
                window.removeEventListener('resize', setHeight);
            };
        }
    });
    return (
        <App ref={appRef}>
            <MenuLinkContainer>
                <MenuLink />
            </MenuLinkContainer>
            {/* <animated.div
                style={{
                    transform: y.interpolate((y) => `translate3d(0, ${y}%, 0)`),
                }}
            >
                <HeaderStandard title={`Archived Works`} />
            </animated.div> */}
            <Switch>
                <Route
                    exact
                    path="/"
                    render={() => <HeaderStandard title={`Selected Works`} />}
                />
                <Route
                    exact
                    path="/archive"
                    render={() => <HeaderStandard title={`Archived Works`} />}
                />
                <Route
                    exact
                    path="/profile"
                    render={() => (
                        <HeaderStandard title={`Profile + Contact`} />
                    )}
                />
                {/* <Route exact path="/archive" component={ArchivedProjects} /> */}
            </Switch>
            <Body>
                <Switch>
                    <Route exact path="/" component={SelectedProjects} />
                    <Route exact path="/archive" component={ArchivedProjects} />
                    <Route
                        exact
                        path={`/project/:slug/:imageNumber`}
                        component={ProjectPage}
                    />
                    <Route
                        exact
                        path={`/archive/:slug/:imageNumber`}
                        component={ArchivedProjectPage}
                    />
                    <Route exact path={`/profile`} component={ProfilePage} />
                </Switch>
            </Body>
            <Menu />
        </App>
    );
};

export default AppComponent;
