import React, { useContext, useReducer } from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { Router, useTrackedHistory } from 'data/config/router';
import client from 'data/apollo-client';
import * as serviceWorker from './serviceWorker';
import {
    ApplicationContext,
    ApplicationReducer,
} from 'data/context/application';
import App from 'components/App';
import 'index.css';

/**
 * Add Apollo, React Router and tracking
 */
function CoreContainer() {
    const history = useTrackedHistory();

    return (
        <Router history={history}>
            <ApolloProvider client={client}>
                <Core />
            </ApolloProvider>
        </Router>
    );
}

/**
 * Add Application state
 */
function Core() {
    const globalStore = useContext(ApplicationContext);
    const [state, dispatch] = useReducer(ApplicationReducer, globalStore);

    return (
        <ApplicationContext.Provider value={{ state, dispatch }}>
            <App />
        </ApplicationContext.Provider>
    );
}

const rootElement = document.getElementById('root');
ReactDOM.render(<CoreContainer />, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
