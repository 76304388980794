import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTransition, animated } from 'react-spring';
import hexToRgba from 'hex-to-rgba';
import { ApplicationContext } from 'data/context/application';
import { MEDIA_QUERIES, OFF_WHITE, OFF_BLACK } from 'utils/constants';

const MenuContainer = animated(
    styled.div({
        position: 'fixed',
        top: '0',
        left: '0',
        zIndex: '2',
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: hexToRgba(OFF_WHITE, 0.9),
    })
);

const Menu = styled.div({
    fontSize: '32px',
    lineHeight: '48px',
    letterSpacing: '-0.27',
    textAlign: 'center',
    [MEDIA_QUERIES.LARGE]: {
        fontSize: '100px',
        lineHeight: '120px',
        letterSpacing: '-0.89',
    },
});

const MenuItem = styled(NavLink)({
    display: 'block',
    textDecoration: 'none',
    color: OFF_BLACK,
});

function MenuComponent() {
    const {
        state: { menu },
        dispatch,
    } = useContext(ApplicationContext);

    const animatedMenuStyles = useTransition(menu, null, {
        from: { display: 'none', opacity: 0 },
        enter: [{ display: 'flex' }, { opacity: 1 }],
        leave: [{ opacity: 0 }, { display: 'none' }],
    });

    function onNavItemClick() {
        dispatch({
            type: 'TOGGLE_MENU',
        });
    }

    return animatedMenuStyles.map(
        ({ item, props, key }) =>
            item && (
                <MenuContainer key={key} style={props}>
                    <Menu>
                        <MenuItem to="/" onClick={onNavItemClick}>
                            Selected Works
                        </MenuItem>
                        <MenuItem to="/archive" onClick={onNavItemClick}>
                            Archive
                        </MenuItem>
                        <MenuItem to="/profile" onClick={onNavItemClick}>
                            Profile + Contact
                        </MenuItem>
                    </Menu>
                </MenuContainer>
            )
    );
}

export default MenuComponent;
