import React from 'react';
import { useSpring, animated } from 'react-spring';
import ProgressiveImage from 'react-progressive-image';
import styled from '@emotion/styled';
import { imageSrc, imageSrcSet } from 'utils/images';

const ImageWrapper = styled.div(
    {
        position: 'relative',
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        '::before': {
            content: '""',
            width: '1px',
            marginLeft: '-1px',
            float: 'left',
            height: '0',
            paddingTop: `calc(${1} / ${1} * 100%)`,
        },
        '::after': {
            content: '""',
            display: 'table',
            clear: 'both',
        },
    },
    ({ placeHolder }) => ({ backgroundImage: `url(${placeHolder})` })
);

const Image = animated(
    styled.img({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    })
);

const fallbackSize = { w: 583, h: 583 };
const imgSizes = [
    {
        w: 335,
        h: 335,
    },
    {
        w: 583,
        h: 583,
    },
];
const imgOptions = {
    q: 70,
};
const sizes = `
    (max-width: 376px) 330px,
    583px
    `;

const ProjectPreviewComponent = ({ title, url, base64Placeholder }) => {
    const [props, set] = useSpring(() => ({
        opacity: 0,
        config: { duration: 700 },
    }));
    const fallback = imageSrc(url, fallbackSize, imgOptions);
    const srcDef = imageSrcSet(url, imgSizes, imgOptions);
    return (
        <ImageWrapper placeHolder={base64Placeholder}>
            <ProgressiveImage
                srcSetData={{
                    srcSet: srcDef,
                    sizes,
                }}
                src={fallback}
            >
                {(src, loading, srcSetData) => {
                    if (loading) {
                        set({ opacity: 0, immediate: true });
                    } else {
                        set({ opacity: 1, immediate: false, delay: 100 });
                    }
                    return loading ? (
                        <div />
                    ) : (
                        <Image
                            alt={title}
                            srcSet={srcSetData.srcSet}
                            sizes={srcSetData.sizes}
                            src={src}
                            style={props}
                        />
                    );
                }}
            </ProgressiveImage>
        </ImageWrapper>
    );
};

export default ProjectPreviewComponent;
