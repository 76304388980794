import React from 'react';
import styled from '@emotion/styled';
import ReactMarkdown from 'react-markdown';
import { LIGHT_GREY, GREY, GUTTERS } from 'utils/constants';
import { gutter } from 'utils/mixins';

const List = styled.div({}, gutter({ top: 1, left: 0, bottom: 1, right: 0 }));
const ListTitle = styled.div(
    {
        color: GREY,
        fontSize: '1.1rem',
        lineHeight: '1.1rem',
    },
    gutter({ top: 0.75, left: 0, bottom: 1 })
);
const ListItems = styled.div({
    borderTop: `solid 1px ${LIGHT_GREY}`,
    fontSize: '0.9rem',
    lineHeight: '1.3rem',
});
const Exhibition = styled.div(
    {
        display: 'flex',
        borderBottom: `solid 1px ${LIGHT_GREY}`,
    },
    gutter({ top: 0.25, left: 0, bottom: 0.3, right: 1 })
);
const ExhibitionYear = styled.div({}, gutter({ left: 0, right: 1 }));
const ExhibitionText = styled(ReactMarkdown)(
    {
        '> p': {
            display: 'block',
            margin: `0 0 ${GUTTERS.small / 2}px`,
        },
    },
    gutter({ bottom: 0 })
);

const ExhibitionList = ({ title, items }) => {
    return (
        <List>
            <ListTitle>{title}</ListTitle>
            <ListItems>
                {items.map((item, i) => (
                    <Exhibition key={i}>
                        <ExhibitionYear>{item.year}</ExhibitionYear>
                        <ExhibitionText>{item.text}</ExhibitionText>
                    </Exhibition>
                ))}
            </ListItems>
        </List>
    );
};

export default ExhibitionList;
