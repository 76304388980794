import React from 'react';
import styled from '@emotion/styled';
import { GREY, MEDIA_QUERIES, DEBUG_LAYOUT } from 'utils/constants';

const PageTitle = styled.div({
    display: 'none',
    color: GREY,
    background: DEBUG_LAYOUT ? 'lightblue' : 'none',
    padding: '10px',
    cursor: 'default',
    fontSize: '16px',
    [MEDIA_QUERIES.MEDIUM]: {
        display: 'flex',
    },
});

const PageTitleComponent = ({ children }) => <PageTitle>{children}</PageTitle>;

export default PageTitleComponent;
