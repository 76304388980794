import React, { useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { useSprings, animated } from 'react-spring';
import clamp from 'core-js/features/math/clamp';
import { useGesture } from 'react-use-gesture';
import Image from 'components/atoms/image/project';

const ProjectImages = animated(
    styled.div({
        display: 'flex',
        cursor: `url('
            data:image/svg+xml,
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="%23fff">
                <path d="M206.707107,261.422836 C207.097631,261.81336 207.097631,262.446525 206.707107,262.837049 L181.541942,287.999942 L233,288 C233.552285,288 234,288.447715 234,289 C234,289.552285 233.552285,290 233,290 L181.543942,289.999942 L206.707107,315.162951 C207.097631,315.553475 207.097631,316.18664 206.707107,316.577164 C206.316582,316.967689 205.683418,316.967689 205.292893,316.577164 L178.422836,289.707107 C178.032311,289.316582 178.032311,288.683418 178.422836,288.292893 L205.292893,261.422836 C205.683418,261.032311 206.316582,261.032311 206.707107,261.422836 Z" transform="translate(-178 -261)"/>
            </svg>
        ') 28 28, auto`,
    })
);
const ImageContainer = animated(
    styled.div({
        display: 'none',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        // background: 'green',
        willChange: 'transform',
        // cursor: 'none',
    })
);
const ImageItem = animated(
    styled.div({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        willChange: 'transform',
        width: '100%',
        height: '100%',
    })
);

function containerWidth(container) {
    return container ? container.offsetWidth : window.innerWidth;
}

// TODO: DRY some things out
function ProjectImagesComponent({
    projectMedia,
    projectSlug,
    prevProject,
    nextProject,
    imageRequested,
}) {
    const index = useRef(0);
    const cursorLeft = useRef(true);
    const container = useRef(null);
    const [props, set] = useSprings(
        projectMedia.length,
        (i) => {
            const displayProp = i < 2 ? 'flex' : 'none';
            return {
                x: i * containerWidth(container.current),
                scale: 1,
                display: displayProp,
                config: {
                    clamp: true,
                },
            };
        },
        { reset: true }
    );

    const gestures = useGesture({
        onMove: ({ xy }) => {
            // left / right of image
            const vWidth = window.innerWidth;
            const leftHit = vWidth / 2;
            const xLeft = xy[0] < leftHit ? true : false;

            // // Adjust xHair based on cursor position
            if (xLeft && !cursorLeft.current) {
                cursorLeft.current = true;
                container.current.style.cssText = `cursor: url('data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='56' height='56' fill='%23FFF '%3E%3Cpath d='M27.423.423a1 1 0 000 1.414L52.588 27H1.13a1 1 0 100 2h51.456L27.423 54.163a1 1 0 101.414 1.414l26.87-26.87a1 1 0 000-1.414L28.837.423a1 1 0 00-1.414 0z'/%3E%3C/svg%3E') 28 28, auto;`;
            } else if (!xLeft && cursorLeft.current) {
                cursorLeft.current = false;
                container.current.style.cssText = `cursor: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="%23FFF "><path d="M27.423.423a1 1 0 000 1.414L52.588 27H1.13a1 1 0 100 2h51.456L27.423 54.163a1 1 0 101.414 1.414l26.87-26.87a1 1 0 000-1.414L28.837.423a1 1 0 00-1.414 0z"/></svg>') 28 28, auto;`;
            }
        },
        onWheel: ({ direction, first }) => {
            if (!first) {
                return false;
            }

            const nextRequested = index.current + direction[1];
            const nextItem = clamp(
                index.current + direction[1],
                0,
                projectMedia.length - 1
            );

            if (nextRequested === projectMedia.length) {
                nextProject();
            }
            if (nextRequested === -1) {
                prevProject();
            }

            imageRequested(nextItem);
            index.current = nextItem;

            set((i) => {
                if (i < nextItem - 1 || i > nextItem + 1)
                    return { display: 'none' };

                const x =
                    (i - nextItem) * containerWidth(container.current) + 0;
                const scale = 1;
                return { x, scale, display: 'flex' };
            });
        },
        onDrag: ({
            down,
            movement: [mx],
            direction: [xDir],
            distance,
            cancel,
            active,
            xy,
            event,
        }) => {
            // left / right of image
            const vWidth = window.innerWidth;
            const leftHit = vWidth / 2;
            const xLeft = xy[0] < leftHit ? true : false;

            if (down && distance > containerWidth(container.current) / 2) {
                const nextRequested = index.current + (xDir > 0 ? -1 : 1);
                const nextItem = clamp(
                    nextRequested,
                    0,
                    projectMedia.length - 1
                );

                if (nextRequested === projectMedia.length) {
                    nextProject();
                }
                if (nextRequested === -1) {
                    prevProject();
                }

                // Fire state update
                imageRequested(nextItem);
                // Cancel drag action and set new current
                cancel((index.current = nextItem));
            } else {
                if (!active && event.type === 'mouseup' && distance < 10) {
                    const nextRequested = index.current + (xLeft ? -1 : 1);
                    const nextItem = clamp(
                        nextRequested,
                        0,
                        projectMedia.length - 1
                    );
                    if (nextRequested === projectMedia.length) {
                        nextProject();
                    }
                    if (nextRequested === -1) {
                        prevProject();
                    }

                    // Fire state update
                    imageRequested(nextItem);
                    // Cancel drag action and set new current
                    cancel((index.current = nextItem));
                }
            }
            set((i) => {
                // If we are not the previous or the next of the current image
                if (i < index.current - 1 || i > index.current + 1)
                    return { display: 'none' };
                const x =
                    (i - index.current) * containerWidth(container.current) +
                    (down ? mx : 0);
                const scale = down
                    ? 1 - distance / containerWidth(container.current) / 2
                    : 1;

                return { x, scale, display: 'flex' };
            });
        },
    });

    // Reset index on media change
    // Also reset anim properties!
    useEffect(() => {
        index.current = 0;
        set((i) => {
            const displayProp = i < 2 ? 'flex' : 'none';
            return {
                x: i * containerWidth(container.current),
                scale: 1,
                display: displayProp,
            };
        });
    }, [set, projectSlug]);

    return (
        <ProjectImages ref={container}>
            {props.map(({ x, display, scale }, i) => {
                return (
                    <ImageContainer
                        {...gestures()}
                        key={`${projectSlug}_${i}`}
                        style={{
                            transform: x.interpolate(
                                (x) => `translate3d(${x}px, 0, 0)`
                            ),
                            display,
                        }}
                    >
                        <ImageItem
                            style={{
                                transform: scale.interpolate(
                                    (scale) => `scale(${scale})`
                                ),
                            }}
                        >
                            <Image
                                imgNumber={i}
                                title={projectMedia[i].title}
                                url={projectMedia[i].url}
                                display={display}
                                base64Placeholder={projectMedia[i].blurUpThumb}
                                width={projectMedia[i].width}
                                height={projectMedia[i].height}
                            />
                        </ImageItem>
                    </ImageContainer>
                );
            })}
        </ProjectImages>
    );
}

// const MemoProjectImages = React.memo(ProjectImages);

export default ProjectImagesComponent;
