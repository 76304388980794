import React from 'react';
import styled from '@emotion/styled';
import { useTracked } from 'data/context/project';
import { GREY, DEBUG_LAYOUT } from 'utils/constants';

const TitleElement = styled.div({
    color: GREY,
    background: DEBUG_LAYOUT ? 'lightblue' : 'none',
    cursor: 'pointer',
    padding: '10px',
    fontSize: '16px',
});

const InfoLink = () => {
    const [state, dispatch] = useTracked();
    return (
        <TitleElement onClick={() => dispatch({ type: 'TOGGLE_INFO' })}>
            {!state.infoOpen ? `+ Info` : `- Close`}
        </TitleElement>
    );
};

export default InfoLink;
