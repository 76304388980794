import React from 'react';
import styled from '@emotion/styled';
import { useSpring, animated } from 'react-spring';
import { NavLink } from 'react-router-dom';
import ImageProject from 'components/atoms/image/projectPreview';
import { OFF_BLACK } from 'utils/constants';
import { gutter } from 'utils/mixins';

const Project = styled(NavLink)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    textDecoration: 'none',
    color: OFF_BLACK,
});

const ProjectTitle = animated(
    styled.div(
        {
            willChange: 'transform',
            fontSize: '0.8rem',
            // lineHeight: '28px',
        },
        gutter({ top: 1, bottom: 1.5 }, { large: { top: 1, bottom: 1 } })
    )
);

const ProjectLink = ({
    title,
    slug,
    base64Placeholder,
    imageURL,
    number,
    hover,
    ImageTitle,
}) => {
    let isHover = hover === number ? true : false;
    if (hover === false) {
        isHover = true;
    }
    const { y, opacity } = useSpring({
        from: { y: 0, opacity: 1 },
        y: isHover ? 0 : 5,
        opacity: isHover ? 1 : 0.2,
        config: { mass: 1, tension: 300, friction: 85 },
    });

    return (
        <Project to={`${slug}/1`}>
            <ImageProject
                base64Placeholder={base64Placeholder}
                title={ImageTitle}
                hover={isHover}
                number={number}
                url={imageURL}
            />
            <ProjectTitle
                style={{
                    opacity,
                    transform: y.interpolate(
                        (yValue) => `translate3d(0, ${yValue}%, 0)`
                    ),
                }}
            >
                {title}
            </ProjectTitle>
        </Project>
    );
};

export default ProjectLink;
