import gql from 'graphql-tag';

const GET_PROFILE = gql`
    {
        profile {
            image {
                title
                url
                blurUpThumb
            }
            introduction
            contactEmail
            soloExhibitions {
                year
                text
            }
            groupExhibitions {
                year
                text
            }
            collections {
                text
            }
        }
    }
`;

export default GET_PROFILE;
