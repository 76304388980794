import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import ProgressiveImage from 'react-progressive-image';
// import SVGLoader from 'components/atoms/icon/svgLoader';
import styled from '@emotion/styled';
import { imageSrc, imageSrcSet } from 'utils/images';
import { OFF_WHITE } from 'utils/constants';

const ImageWrapper = styled.div(
    {
        position: 'relative',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        width: '100%',
        height: '100%',
        '::before': {
            content: '""',
            width: '1px',
            marginLeft: '-1px',
            float: 'left',
            height: '0',
        },
        '::after': {
            content: '""',
            display: 'table',
            clear: 'both',
        },
    },
    ({ placeHolder, imgWidth, imgHeight }) => ({
        backgroundImage: `url(${placeHolder})`,
        '::before': {
            paddingTop: `calc(${imgHeight} / ${imgWidth} * 100%)`,
        },
    })
);

const Loader = animated(
    styled.div({
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    })
);

const LoaderText = animated(
    styled.div({
        color: OFF_WHITE,
    })
);

const ImageCanvas = animated(
    styled.div({
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        background: OFF_WHITE,
        // background: 'red',
    })
);

const Image = animated(
    styled.img({
        position: 'absolute',
        top: 0,
        left: 0,
        objectFit: 'contain',
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
    })
);

const fallbackSize = { w: 1300 };
const imgSizes = [
    {
        w: 1800,
    },
    {
        w: 1300,
    },
    {
        w: 900,
    },
    {
        w: 480,
    },
];

const imgOptions = {
    q: 85,
};
const sizes = `
    100vw
`;

const ProjectImageContainer = animated(
    ({ imgNumber, title, url, base64Placeholder, width, height, display }) => {
        const displayImage = display !== 'none' ? true : false;
        const fallback = imageSrc(url, fallbackSize, imgOptions);
        const srcDef = imageSrcSet(url, imgSizes, imgOptions);

        return (
            <ImageWrapper
                placeHolder={base64Placeholder}
                imgWidth={width}
                imgHeight={height}
            >
                {displayImage && (
                    <ProjectImage
                        key={imgNumber}
                        url={url}
                        width={width}
                        height={height}
                        srcDef={srcDef}
                        fallback={fallback}
                        imgNumber={imgNumber}
                        title={title}
                    />
                )}
            </ImageWrapper>
        );
    }
);

const ProjectImage = ({
    url,
    width,
    height,
    srcDef,
    fallback,
    imgNumber,
    title,
}) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const imageProps = useSpring({
        from: { opacity: 0 },
        opacity: imageLoaded ? 1 : 0,
        config: { duration: 750 },
    });
    const loaderProps = useSpring({
        from: { opacity: 0 },
        opacity: imageLoaded ? 0 : 1,
        delay: 1500,
        config: { duration: 750 },
    });
    const canvasProps = useSpring({
        from: { opacity: 0 },
        opacity: imageLoaded ? 1 : 0,
        config: { duration: 750 },
    });

    return [
        <ImageCanvas key={`${imgNumber}_canvas`} style={canvasProps} />,
        <Loader style={loaderProps} key={`loader_${url}`}>
            {/* <SVGLoader width={width} height={height} color={OFF_BLACK} /> */}
            <LoaderText>Loading image</LoaderText>
        </Loader>,
        <ProgressiveImage
            srcSetData={{
                srcSet: srcDef,
                sizes,
            }}
            src={fallback}
            key={`image_${url}`}
        >
            {(src, loading, srcSetData) => {
                return (
                    !loading && (
                        <Image
                            onLoad={() => {
                                setImageLoaded(true);
                            }}
                            alt={title}
                            srcSet={srcSetData.srcSet}
                            sizes={srcSetData.sizes}
                            src={src}
                            style={imageProps}
                        />
                    )
                );
            }}
        </ProgressiveImage>,
    ];
};

export default ProjectImageContainer;
