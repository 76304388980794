import { useReducer } from 'react';
import { createContainer } from 'react-tracked';

const initialState = {
    active: 0,
    nextPreview: false,
    prevPreview: false,
    infoOpen: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'PROJECT_LOADED':
            return {
                ...state,
                nextPreview: false,
                prevPreview: false,
                infoOpen: false,
                active: 0,
            };
        case 'IMAGE_REQUESTED':
            const nextImageNum = action.payload.imgNum;
            // console.log('redux', nextImageNum);
            return {
                ...state,
                active: nextImageNum,
            };
        case 'CLOSE_PANELS':
            return {
                ...state,
                nextPreview: false,
                prevPreview: false,
                infoOpen: false,
            };
        case 'OPEN_NEXT_PREVIEW':
            return { ...state, nextPreview: true };
        case 'OPEN_PREV_PREVIEW':
            return { ...state, prevPreview: true };
        case 'TOGGLE_INFO':
            return {
                ...state,
                infoOpen: !state.infoOpen,
                nextPreview: false,
                prevPreview: false,
            };
        default:
            return state;
    }
};

const useValue = () => useReducer(reducer, initialState);

export const {
    Provider,
    useTracked,
    useUpdate,
    useTrackedState,
} = createContainer(useValue);

// export { ProjectContext, ProjectReducer };
