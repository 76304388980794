import gql from 'graphql-tag';

const GET_ARCHIVED_PROJECT = gql`
    {
        allArchives {
            title
            slug
            description
            media {
                title
                url
                blurUpThumb
                width
                height
            }
        }
    }
`;

export default GET_ARCHIVED_PROJECT;
