import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { animated, useSpring } from 'react-spring';
import { NavLink } from 'react-router-dom';
import { useTracked } from 'data/context/project';
import { GREY, OFF_BLACK, OFF_WHITE, MEDIA_QUERIES } from 'utils/constants';
import { gutter } from 'utils/mixins';

const PrevProjectLink = styled.div({
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: 4,
    userSelect: 'none',
    pointerEvents: 'none',
});
const PrevProject = animated(
    styled.div(
        {
            pointerEvents: 'all',
            position: 'absolute',
            zIndex: '2',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-end',
            textAlign: 'right',
            width: '80%',
            height: '100%',
            background: OFF_WHITE,
            willChange: 'transform',
            cursor: 'default',
            [MEDIA_QUERIES.MEDIUM]: {
                width: '50%',
            },
        },
        gutter(
            { top: 0, right: 1, bottom: 0, left: 1 },
            {
                medium: { right: 2, left: 2 },
                large: { right: 3.5, left: 3.5 },
            }
        )
    )
);
const BlankHitArea = styled.div(
    {
        pointerEvents: 'all',
        position: 'absolute',
        zIndex: '1',
        top: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        cursor: `url("
            data:image/svg+xml,
            %3Csvg xmlns='http://www.w3.org/2000/svg' width='56' height='56'%3E%3Cpath fill='%23FFF' d='M54.293.293l1.414 1.414L29.414 28l26.293 26.293-1.414 1.414L28 29.414 1.707 55.707.293 54.293 26.586 28 .293 1.707 1.707.293 28 26.586 54.293.293z'/%3E%3C/svg%3E
        ") 28 28, auto`,
        // background: `rgba(122, 212, 200, 0.5)`,
    },
    ({ isOpen }) => ({
        display: isOpen ? 'block' : 'none',
    })
);

const NavigationDirection = animated(
    styled.div({
        fontSize: '0.8rem',
        lineHeight: '1.1rem',
        color: GREY,
        marginBottom: '16px',
    })
);
const ProjectLink = styled(NavLink)({
    fontSize: '1.8rem',
    lineHeight: '2rem',
    textDecoration: 'none',
    color: OFF_BLACK,
    [MEDIA_QUERIES.MEDIUM]: {
        // fontSize: '40px',
        // lineHeight: '48px',
    },
});

const PrevProjectLinkComponent = ({
    prevTitle: title,
    prevSlug: slug,
    context,
}) => {
    const [{ prevPreview: open }, dispatch] = useTracked();

    const { x } = useSpring({
        from: { x: -100 },
        x: open ? 0 : -100,
    });
    const [localData, setLocalData] = useState({
        title: '',
        slug: '',
    });

    const closePanels = () => {
        dispatch({
            type: 'CLOSE_PANELS',
        });
    };

    useEffect(() => {
        // Allow time for animation to complete
        setTimeout(() => {
            setLocalData({
                title,
                slug,
            });
        }, 750);
    }, [title, slug]);

    return (
        <PrevProjectLink>
            <PrevProject
                style={{
                    transform: x.interpolate((x) => `translate3d(${x}%, 0, 0)`),
                }}
            >
                <NavigationDirection>Previous project</NavigationDirection>
                <ProjectLink to={`/${context}/${localData.slug}/1`}>
                    {localData.title}
                </ProjectLink>
            </PrevProject>
            <BlankHitArea isOpen={open} onClick={closePanels} />
        </PrevProjectLink>
    );
};

export default PrevProjectLinkComponent;
