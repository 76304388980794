import React from 'react';
import { Query } from 'react-apollo';
import GET_PROFILE from 'data/queries/get-profile.graphql.js';
import ProfilePage from 'components/templates/profilePage';

const ProfilePageContainer = () => (
    <Query query={GET_PROFILE}>
        {({ loading, error, data }) => {
            if (loading) {
                return <p>Loading...</p>;
            }
            if (error) {
                return <p>Error :(</p>;
            }
            return <ProfilePage profile={data.profile} />;
        }}
    </Query>
);

export default ProfilePageContainer;
