import React from 'react';
import styled from '@emotion/styled';
import Branding from 'components/molecules/branding';
import InfoLink from 'components/molecules/infoLink';
import { SIDEBAR_WIDTH, MEDIA_QUERIES, DEBUG_LAYOUT } from 'utils/constants';

const Header = styled.div({
    display: 'flex',
    [MEDIA_QUERIES.MEDIUM]: {
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: `-${SIDEBAR_WIDTH}px`,
        left: '0px',
        transform: 'rotate(-90deg)',
        transformOrigin: 'top left',
        width: `calc(100vh - ${0}px)`,
        height: `${SIDEBAR_WIDTH}px`,
        willChange: 'transform',
        paddingRight: 0
    }
});

const BrandingContainer = styled.div({
    padding: '4px 10px 10px',
    background: DEBUG_LAYOUT ? 'orange' : 'none',
    [MEDIA_QUERIES.MEDIUM]: {
        padding: '10px',
        position: 'relative'
    }
});
const InfoLinkContainer = styled.div({
    display: 'none',
    [MEDIA_QUERIES.MEDIUM]: {
        background: DEBUG_LAYOUT ? 'lightblue' : 'none',
        display: 'block',
        position: 'absolute',
        left: '30px'
    }
});

const HeaderComponent = () => (
    <Header>
        <InfoLinkContainer>
            <InfoLink />
        </InfoLinkContainer>
        <BrandingContainer>
            <Branding />
        </BrandingContainer>
    </Header>
);

export default HeaderComponent;
