import React from 'react';
import styled from '@emotion/styled';
import ProjectGrid from 'data/containers/projectGrid';
import { MEDIA_QUERIES, globalPadding } from 'utils/constants';

const ProjectsContainer = styled.div(
    {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '70px',
        [MEDIA_QUERIES.LARGE]: {
            paddingTop: 'calc(25vh - 84px) !important',
        },
    },
    globalPadding()
);

const SelectedProjects = () => {
    return (
        <ProjectsContainer>
            <ProjectGrid />
        </ProjectsContainer>
    );
};

export default SelectedProjects;
