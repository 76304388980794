import React from 'react';
import styled from '@emotion/styled';
import { useTrackedState } from 'data/context/project';
import {
    GREY,
    SIDEBAR_WIDTH,
    DEBUG_LAYOUT,
    MEDIA_QUERIES,
} from 'utils/constants';

const ImageCount = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    // paddingRight: `20px`,
    padding: '10px',
    color: GREY,
    background: DEBUG_LAYOUT ? 'lightblue' : 'none',
    cursor: 'default',
    fontSize: '16px',
    [MEDIA_QUERIES.MEDIUM]: {
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: `-${SIDEBAR_WIDTH}px`,
        right: '0px',
        transform: 'rotate(90deg)',
        transformOrigin: 'top right',
        width: `calc(100vh - ${0}px)`, // bcs it's on its side!
        height: `${SIDEBAR_WIDTH}px`,
        willChange: 'transform',
        paddingRight: 0,
    },
});

const padCount = (count) => {
    const prefix = +count < 10 ? `0` : ``;
    return `${prefix}${count}`.slice(-2);
};

const ImageCountComponent = ({ totalMedia }) => {
    const state = useTrackedState();
    const imageNumber = state.active + 1;
    const totalImages = totalMedia;

    return (
        <ImageCount>{`${padCount(+imageNumber)}—${padCount(
            totalImages
        )}`}</ImageCount>
    );
};

export default ImageCountComponent;
